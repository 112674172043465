import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteState, apiGetStateTrashData, apiRecoverState } from 'services/StateService'

export const getTrashStates = createAsyncThunk('stateTrash/data/getTrashStates',async (data) => {
    const response = await apiGetStateTrashData(data)
    return response.data
})

export const recoverState = async (data) => {
    const response = await apiRecoverState(data)
    return response.data
}

export const deleteState = async (data) => {
    const response = await apiDeleteState(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    stateTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'stateTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashStates.fulfilled, (state, action) => {
            state.stateTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashStates.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
