import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteCity, apiGetCityData, apiPutCity } from 'services/CityService'

export const getCity = createAsyncThunk('cityEdit/data/getCity', async (data) => {
    const response = await apiGetCityData(data)
    return response.data
})

export const updateCity = async (data) => {
    const response = await apiPutCity(data)
    return response.data
}

export const deleteCity = async (data) => {
    const response = await apiDeleteCity(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'cityEdit/data',
    initialState: {
        loading: false,
        cityData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getCity.fulfilled, (state, action) => {
            state.cityData = action.payload.city
            state.loading = false
        })
        builder.addCase(getCity.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
