import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetOrderData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}order/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.client_id &&
    data.pageSize &&
    !data.query &&
    !data.sort.order
  ) {
    // console.log("pge");
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=id&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][0][filters][0][field]=client_id&searchCriteria[filter_groups][0][filters][0][value]=${data.client_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.type !== "order"
          ? `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=id&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=MR&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=id&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.client_id && data.sort.order && !data.query) {
    // console.log("sort");
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][0][filters][0][field]=client_id&searchCriteria[filter_groups][0][filters][0][value]=${data.client_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url:
        data.type !== "order"
          ? `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=MR&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.type !== "order"
          ? `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=MR&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`
          : `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url:
        data.type !== "order"
          ? `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=MR&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`
          : `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetOrderTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}order/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.type === "MR"
          ? `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=MR&searchCriteria[filter_groups][0][filters][0][condition_type]=in`
          : `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,

      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url:
        data.type === "MR"
          ? `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=MR&searchCriteria[filter_groups][0][filters][0][condition_type]=in`
          : `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.type === "MR"
          ? `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=MR&searchCriteria[filter_groups][1][filters][0][condition_type]=in`
          : `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url:
        data.type === "MR"
          ? `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=MR&searchCriteria[filter_groups][1][filters][0][condition_type]=in`
          : `${BASE_URL}order/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=notes&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=user&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=client&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=status&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=total&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=distributor&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetOrderProductData(data) {
  if (data.type !== undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=${data.type}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=id&searchCriteria[filter_groups][2][filters][0][value]=${data.selectedProductId}&searchCriteria[filter_groups][2][filters][0][condition_type]=nin`,
      method: "get",
    });
  }
}

export async function apiGetOrderClientData(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}clients/searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=email&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=mobile&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=pan_gst&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=${data.type}&searchCriteria[filter_groups][1][filters][0][condition_type]=in&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
    method: "get",
  });
}

export async function apiPostOrder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}order`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusOrder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}order/status`,
    method: "put",
    data,
  });
}

export async function apiPutOrder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}order/${data.order.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverOrder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}order/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteOrder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}order`,
    method: "delete",
    data,
  });
}

export async function apiGetHistoryOrderData(data) {
  if (data.client_id && data.pageSize && !data.query && !data.sort.order) {
    // console.log("pge");
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=id&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][0][filters][0][field]=client_id&searchCriteria[filter_groups][0][filters][0][value]=${data.client_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.client_id && data.sort.order && !data.query) {
    // console.log("sort");
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=type&searchCriteria[filter_groups][0][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][0][filters][0][field]=client_id&searchCriteria[filter_groups][0][filters][0][value]=${data.client_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}
