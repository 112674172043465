import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'stateTrash/state',
    initialState: {
        deleteConfirmation: false,
        recoverConfirmation: false,
        selectedState: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleRecoverConfirmation: (state, action) => {
            state.recoverConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedState: (state, action) => {
            state.selectedState = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation,
    toggleRecoverConfirmation,
    setSortedColumn,
    setSelectedState
} = stateSlice.actions

export default stateSlice.reducer
