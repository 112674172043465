import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetUserAttendanceData(data) {
  if (data.reportType[0] === "month" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/${data.reportType[0]}/${data.MR}/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "month" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/${data.reportType[0]}/${data.MR}/${
        data.year
      }/${data.month}/searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key === ("login" || "logout") ? "time" : data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "day" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/${data.reportType[0]}/${data.MR}/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "day" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/${data.reportType[0]}/${data.MR}/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "all" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/month/${data.reportType[0]}/${data.year}/0${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "all" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/activity/month/${data.reportType[0]}/${data.year}/0${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=user&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetAllDailyLocation(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}report/activity/day/${data.MR}/${data.year}/${data.month}/${data.date}`,
    method: "get",
  }).catch((e) => {
    return e.response;
  });
}

// ! Download Daily Report Activity
export async function apiGetMrDailyReport(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}report/activity/day/download/${data.MR}/${data.year}/${data.month}/${data.date}`,
    method: "get",
    responseType: "blob", // Add responseType to get the response as a blob
  }).catch((e) => {
    return e.response;
  });
}
// ! Download Monthly Report Activity
export async function apiGetMrMonthlyReport(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}report/activity/month/download/${data.MR}/${data.year}/${data.month}`,
    method: "get",
    responseType: "blob", // Add responseType to get the response as a blob
  }).catch((e) => {
    return e.response;
  });
}

export async function apiGetAttendanceData(data) {
  if (data.reportType[0] === "all" && !data.sort.order && !data.query) {
    // console.log("Running");

    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "all" && data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=user&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=user&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "month" && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "month" && data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "day" && !data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "day" && data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
          : `${BASE_URL}report/attendance/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

// ! Attendance Report Download

export async function apiGetAttendanceReport(data) {
  if (data.reportType[0] === "all") {
    return ApiService.fetchData({
      url: `${BASE_URL}report/attendance/download/${data.selected_date.start_date}/${data.selected_date.end_date}`,
      method: "get",
      responseType: "blob", // Add responseType to get the response as a blob
    }).catch((e) => {
      return e.response;
    });
  } else {
    return ApiService.fetchData({
      url: `${BASE_URL}report/attendance/download/${data.selected_date.start_date}/${data.selected_date.end_date}/searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
      responseType: "blob", // Add responseType to get the response as a blob
    }).catch((e) => {
      return e.response;
    });
  }
}
