import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetOrderClientData,
  apiGetOrderProductData,
} from "services/OrderService";

export const getOrderProducts = createAsyncThunk(
  "orderProductList/data/getOrderProducts",
  async (data) => {
    const response = await apiGetOrderProductData(data);
    return response.data;
  }
);

export const getOrderClients = createAsyncThunk(
  "orderClientList/data/getOrderClients",
  async (data) => {
    const response = await apiGetOrderClientData(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  type: "",
  selectedProductId: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

export const initialState = {
  loading: false,
  clientLoading: false,
  isFound: false,
  navigationDialogConfirmation: false,
  navigationPath: "/order/view",
  orderProductList: [],
  orderClientList: [],
  addedOrderProductList: [],
  clientDetails: [],
  distributorDetails: [],
  tableData: initialTableData,
  filterData: initialFilterData,
};

const dataSlice = createSlice({
  name: "orderProductList/data",
  initialState,
  reducers: {
    reset: () => initialState,
    addOrderProduct: (state, action) => {
      state.addedOrderProductList.push(action.payload);
    },
    updateProductQuantity: (state, action) => {
      if (action.payload.value !== null) {
        state.addedOrderProductList.map((item) => {
          if (item[0]?.product?.configurable) {
            if (
              item[0]?.selectedProductSize?.size_id ===
                action.payload.configurableId &&
              item[0]?.product?.id === action.payload.productId
            ) {
              item[0].quantity = action.payload.value;
              item[0].scheme_applied = action.payload.scheme_applied;
            }
          } else if (item[0]?.product?.id === action.payload.productId) {
            item[0].quantity = action.payload.value;
            item[0].scheme_applied = action.payload.scheme_applied;
          }
        });
      }
    },
    discardAddedProduct: (state, action) => {
      state.addedOrderProductList.splice(action.payload, 1);
    },
    setNavigationDialogConfirmation: (state, action) => {
      state.navigationDialogConfirmation = action.payload;
    },
    setNavigationPath: (state, action) => {
      state.navigationPath = action.payload;
    },
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setDistributorDetails: (state, action) => {
      state.distributorDetails = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderProducts.fulfilled, (state, action) => {
      state.orderProductList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getOrderProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderClients.fulfilled, (state, action) => {
      state.orderClientList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.clientLoading = false;
      state.isFound = true;
    });
    builder.addCase(getOrderClients.pending, (state) => {
      state.clientLoading = true;
      state.isFound = false;
    });
  },
});

export const {
  reset,
  addOrderProduct,
  updateProductQuantity,
  discardAddedProduct,
  setNavigationDialogConfirmation,
  setNavigationPath,
  setClientDetails,
  setDistributorDetails,
  setTableData,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
