import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { reset } from 'views/order/OrderForm/store/dataSlice'
import { setOpenFeedback } from 'store/base/commonSlice'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async ({ email, password }) => {
        try {
			const resp = await apiSignIn({ email, password })
			if (resp.data) {
				const { token, open_feedback } = resp.data
				dispatch(onSignInSuccess(token))
				dispatch(setOpenFeedback(open_feedback))
				if(resp.data.data.email) {
					dispatch(setUser({
						localStorageOpenFeedback: open_feedback,
						email: resp.data.data.email,
						userName: resp.data.data.name,
						role: resp.data.data.role,
						authority: [resp.data.data.role],
						id: resp.data.data.id,
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.errors?.[0].message || errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		dispatch(reset())
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth