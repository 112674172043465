import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    headerTitle: '',
    openFeedback: '',
    previousPath: null
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setHeaderTitle: (state, action) => {
            state.headerTitle = action.payload
        },
        setOpenFeedback: (state, action) => {
            state.openFeedback = action.payload
        },
        setPreviousPath: (state, action) => {
            state.previousPath = action.payload
        }
	},
})

export const { setCurrentRouteKey, setHeaderTitle, setOpenFeedback, setPreviousPath } = commonSlice.actions

export default commonSlice.reducer