import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiDeleteReminder,
  apiGetReminderData,
  apiPutReminder,
} from "services/ReminderService";

export const getReminder = createAsyncThunk(
  "reminderEdit/data/getReminder",
  async (data) => {
    const response = await apiGetReminderData(data);
    return response.data;
  }
);

export const updateReminder = async (data) => {
  const response = await apiPutReminder(data);
  return response.data;
};

export const deleteReminder = async (data) => {
  const response = await apiDeleteReminder(data);
  return response.data;
};

export const initialState = {
  loading: false,
  reminderData: [],
};

const dataSlice = createSlice({
  name: "reminderEdit/data",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReminder.fulfilled, (state, action) => {
      state.reminderData = action.payload.reminder;
      state.loading = false;
    });
    builder.addCase(getReminder.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { reset } = dataSlice.actions;

export default dataSlice.reducer;
