import { createSlice } from '@reduxjs/toolkit'

const reminderSlice = createSlice({
    name: 'reminder/state',
    initialState: {
        deleteConfirmation: false,
        selectedReminder: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedReminder: (state, action) => {
            state.selectedReminder = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedReminder
} = reminderSlice.actions

export default reminderSlice.reducer
