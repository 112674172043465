import ApiService from "./ApiService";
import { BASE_URL } from "constants/api.constant";

export async function apiGetGeneralSettings() {
  return ApiService.fetchData({
    url: `${BASE_URL}setting/general`,
    method: "get",
  });
}

export async function apiPutGeneralSettings(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}setting/general`,
    method: "put",
    data,
  });
}

export async function apiGetClientRequiredFields() {
  return ApiService.fetchData({
    url: `${BASE_URL}setting/client`,
    method: "get",
  });
}

export async function apiPutSettingClientRequiredFields(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}setting/client`,
    method: "put",
    data,
  });
}
