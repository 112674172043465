import { createSlice } from '@reduxjs/toolkit'

const orderProductSlice = createSlice({
    name: 'order/state',
    initialState: {
        deleteConfirmation: false,
        selectedProduct: '',
        globalDisable: {},
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload
        },
        setGlobalDisable: (state, action) => {
            state.globalDisable = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedProduct,
    setGlobalDisable
} = orderProductSlice.actions

export default orderProductSlice.reducer
