import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteUser, apiGetUserData, apiPutUser } from 'services/UserService'

export const getUser = createAsyncThunk('userEdit/data/getUser', async (data) => {
    const response = await apiGetUserData(data)
    return response.data
})

export const updateUser = async (data) => {
    const response = await apiPutUser(data)
    return response.data
}

export const deleteUser = async (data) => {
    const response = await apiDeleteUser(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'userEdit/data',
    initialState: {
        loading: false,
        userData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getUser.fulfilled, (state, action) => {
            state.userData = action.payload.user
            state.loading = false
        })
        builder.addCase(getUser.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
