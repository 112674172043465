import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetMROrderData(data) {
  if (data.sorting === true && data.type["icon"] === "order") {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][2][filters][0][condition_type]=in&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.sorting === true &&
    data.type["icon"] === "order_error_code"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/${data.user_data.date}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order_error_code") {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/${data.user_data.date}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.sorting === true &&
    data.type["icon"] === "order_error_monthly_code"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order_error_monthly_code") {
    console.log("Run");
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.sorting === true &&
    data.type["icon"] === "reminder_error_code"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/${data.user_data.date}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "reminder_error_code") {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/${data.user_data.date}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.sorting === true &&
    data.type["icon"] === "reminder_error_monthly_code"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "reminder_error_monthly_code") {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sorting === true && data.type["icon"] === "mrOrder") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=MR&searchCriteria[filter_groups][2][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.sorting === true &&
    (data.type["icon"] === "reminder" ||
      data.type["icon"] === "expense" ||
      data.type["icon"] === "client")
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order" && data.id !== "history") {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=created_by&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][2][filters][0][condition_type]=in&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "mrOrder") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=created_by&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=MR&searchCriteria[filter_groups][2][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sorting === true && data.type["icon"] === "non_prod_order") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=ZeroSum&searchCriteria[filter_groups][2][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "non_prod_order") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=created_by&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=ZeroSum&searchCriteria[filter_groups][2][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.paging === true || data.selecting === true) &&
    (data.type["icon"] === "reminder" ||
      data.type["icon"] === "expense" ||
      data.type["icon"] === "client" ||
      data.type["icon"] === "reminder_error_code")
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.paging === true || data.selecting === true) &&
    data.type["icon"] === "order"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=At Shop,On Phone&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.paging === true || data.selecting === true) &&
    data.type["icon"] === "non_prod_order"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=ZeroSum&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.paging === true || data.selecting === true) &&
    data.type["icon"] === "mrOrder"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=MR&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.type["icon"] === "client" &&
    data.type["id"] === "last_visit"
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"]}/${data.user_data.last_visit_id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if ((data.sorting === true && data.type["icon"]) === "total_visit") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "total_visit") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "booked_order") {
    return ApiService.fetchData({
      url: `${BASE_URL}report/order/booked/daily/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/${data.user_data.date}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_by&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetMRMonthOrderData(data) {
  if (data.type["icon"] === "order" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.month
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=type&searchCriteria[filter_groups][3][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][3][filters][0][condition_type]=in&searchCriteria[filter_groups][4][filters][0][field]=status&searchCriteria[filter_groups][4][filters][0][value]=ZeroSum&searchCriteria[filter_groups][4][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.month
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=type&searchCriteria[filter_groups][3][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][3][filters][0][condition_type]=in&searchCriteria[filter_groups][4][filters][0][field]=status&searchCriteria[filter_groups][4][filters][0][value]=ZeroSum&searchCriteria[filter_groups][4][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "non_prod_order" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${data.user_data.id}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "non_prod_order" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${data.user_data.id}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "mrOrder" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${data.user_data.id}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=type&searchCriteria[filter_groups][3][filters][0][value]=MR&searchCriteria[filter_groups][3][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "mrOrder" && data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${data.user_data.id}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=type&searchCriteria[filter_groups][3][filters][0][value]=MR&searchCriteria[filter_groups][3][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.type["icon"] === "reminder" ||
      data.type["icon"] === "expense" ||
      data.type["icon"] === "client") &&
    !data.sort.order
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.month
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    (data.type["icon"] === "reminder" ||
      data.type["icon"] === "expense" ||
      data.type["icon"] === "client") &&
    data.sort.order
  ) {
    return ApiService.fetchData({
      url: `${BASE_URL}${data.type["icon"] + "s"}/searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.month
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${
        data.user_data.year
      }&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${
        data.user_data.id
      }&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && data.type["icon"] === "reminder_error_code") {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "reminder_error_code" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/reminder/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && data.type["icon"] === "order_error_code") {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order_error_code" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}invalid/location/order/months/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}/searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[pageSize]=${data.pageSize}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "total_visit" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=created_by&searchCriteria[filter_groups][2][filters][0][value]=${data.user_data.id}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "booked_order" && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}report/order/booked/monthly/${data.user_data.id}/${data.user_data.year}/${data.user_data.month}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetDayMonthOrderData(data) {
  if (data.type["id"] === "order_day" && data.sorting === true) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_at&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=ZeroSum&searchCriteria[filter_groups][2][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["id"] === "order_day") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_at&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][1][filters][0][condition_type]=in&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=ZeroSum&searchCriteria[filter_groups][2][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order_month" && data.sorting === true) {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][2][filters][0][condition_type]=in&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "order_month") {
    return ApiService.fetchData({
      url: `${BASE_URL}orders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=type&searchCriteria[filter_groups][2][filters][0][value]=At%20Shop,On%20Phone&searchCriteria[filter_groups][2][filters][0][condition_type]=in&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=ZeroSum&searchCriteria[filter_groups][3][filters][0][condition_type]=neq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["id"] === "expense_day" && data.sorting === true) {
    return ApiService.fetchData({
      url: `${BASE_URL}expenses/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[sortOrders][0][fields]=${
        data.sort.key
      }&searchCriteria[sortOrders][0][direction]=${
        data.sort.order
      }&searchCriteria[filter_groups][0][filters][0][field]=created_at&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["id"] === "expense_day") {
    return ApiService.fetchData({
      url: `${BASE_URL}expenses/searchCriteria[currentPage]=${
        data.pageIndex
      }&searchCriteria[pageSize]=${
        data.pageSize
      }&searchCriteria[filter_groups][0][filters][0][field]=created_at&searchCriteria[filter_groups][0][filters][0][value]=${
        data.user_data.year +
        "-" +
        data.user_data.month +
        "-" +
        data.user_data.date
      }&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "expense_month" && data.sorting === true) {
    return ApiService.fetchData({
      url: `${BASE_URL}expenses/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.type["icon"] === "expense_month") {
    return ApiService.fetchData({
      url: `${BASE_URL}expenses/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=month&searchCriteria[filter_groups][0][filters][0][value]=${data.user_data.month}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=year&searchCriteria[filter_groups][1][filters][0][value]=${data.user_data.year}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}
