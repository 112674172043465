import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetCityData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.state && !data.city) {
    // ! In clause is added so multiple state id's can be passed
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[filter_groups][0][filters][0][field]=state_id&searchCriteria[filter_groups][0][filters][0][value]=${data.state}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.state && data.city && !data.city_id) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.city}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=state_id&searchCriteria[filter_groups][1][filters][0][value]=${data.state}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.state && data.city_id && data.city) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.city_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=state_id&searchCriteria[filter_groups][1][filters][0][value]=${data.state}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.searchByCities) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.searchByCities}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=created_by_name&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}cities/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=created_by_name&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetCityTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostCity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}city`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusCity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}city/status`,
    method: "put",
    data,
  });
}

export async function apiPutCity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}city/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverCity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}city/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteCity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}city`,
    method: "delete",
    data,
  });
}
