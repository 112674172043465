import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetStateData,
  apiPutState,
  apiDeleteState,
  apiChangeStatusState,
} from "services/StateService";

export const getStates = createAsyncThunk(
  "stateList/data/getStates",
  async (data) => {
    const response = await apiGetStateData(data);
    return response.data;
  }
);

export const changeStatusState = async (data) => {
  const response = await apiChangeStatusState(data);
  return response.data;
};

export const updateState = async (data) => {
  const response = await apiPutState(data);
  return response.data;
};

export const deleteState = async (data) => {
  const response = await apiDeleteState(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  stateStatus: 0,
};

export const initialState = {
  loading: false,
  stateList: [],
  openFeedback: "",
  tableData: initialTableData,
  filterData: initialFilterData,
};

const dataSlice = createSlice({
  name: "stateList/data",
  initialState,
  reducers: {
    reset: () => initialState,
    updateStateList: (state, action) => {
      state.stateList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.stateList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getStates.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  reset,
  updateStateList,
  setTableData,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
