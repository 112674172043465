import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetAdvanceData(data) {
  console.log(data, "dataApi");
  return ApiService.fetchData({
    url: `${BASE_URL}report/advanced/${data?.employee}/${data?.distributor}/${data?.product}/${data?.dateRange?.start_date}/${data?.dateRange?.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][direction]=${data?.sort?.order}&searchCriteria[sortOrders][0][fields]=${data?.sort?.key}&searchCriteria[orderPageSize]=${data?.orderPagezise}&searchCriteria[orderCurrentPage]=${data?.orderPageIndex}`,

    method: "get",
  }).catch((e) => {
    return e.response;
  });
}

export async function apiGetDistData(data) {
  if (data.search) {
    return ApiService.fetchData({
      url: `${BASE_URL}clients/searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.search}&searchCriteria[filter_groups][0][filters][0][condition_type]=like`,

      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else {
    return ApiService.fetchData({
      url: `${BASE_URL}clients/searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=t&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=email&searchCriteria[filter_groups][0][filters][1][value]=t&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=mobile&searchCriteria[filter_groups][0][filters][2][value]=t&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=pan_gst&searchCriteria[filter_groups][0][filters][3][value]=t&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=type&searchCriteria[filter_groups][1][filters][0][value]=Distributor&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`,

      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetadvanceDownloadList(data) {
  const product = data.product ? data.product : ['all'];
  const distributor = data.distributor ? data.distributor : ['all'];
  const employee = data.employee ? data.employee : ['all'];
  return ApiService.fetchData({
    url: `${BASE_URL}report/advanced/download/${data.ext}/${employee}/${distributor}/${product}/${data.selectedDate.start_date}/${data.selectedDate.end_date}`,
    method: "get",
    responseType: "blob",
  }).catch((e) => {
    return e.response;
  });
}
