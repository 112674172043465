import { createSlice } from "@reduxjs/toolkit";

const attendanceSlice = createSlice({
  name: "attendance/state",
  initialState: {
    deleteConfirmation: false,
    selectedLocation: "",
    sortedColumn: () => {},
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSortedColumn: (state, action) => {
      state.sortedColumn = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSortedColumn,
  setSelectedLocation,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
