import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetMRProductList } from "services/MrProductListService";

export const getMrProductList = createAsyncThunk(
  "mrProductList/data/getProductList",
  async (data) => {
    const response = await apiGetMRProductList(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  // pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};
export const initialState = {
  loading: false,
  productList: [],
  tableData: initialTableData,
};

const dataSlice = createSlice({
  name: "mrProductList/data",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMrProductList.fulfilled, (state, action) => {
      // console.log("Action", action.payload);
      state.productList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getMrProductList.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { reset, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
