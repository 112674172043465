import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteArea, apiGetAreaData, apiPutArea } from 'services/AreaService'

export const getArea = createAsyncThunk('areaEdit/data/getAreas', async (data) => {
    const response = await apiGetAreaData(data)
    return response.data
})

export const updateArea = async (data) => {
    const response = await apiPutArea(data)
    return response.data
}

export const deleteArea = async (data) => {
    const response = await apiDeleteArea(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'areaEdit/data',
    initialState: {
        loading: false,
        areaData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getArea.fulfilled, (state, action) => {
            state.areaData = action.payload.area
            state.loading = false
        })
        builder.addCase(getArea.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
