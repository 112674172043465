import { createSlice } from '@reduxjs/toolkit'

const orderSlice = createSlice({
    name: 'orderList/state',
    initialState: {
        deleteConfirmation: false,
        selectedOrder: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedOrder
} = orderSlice.actions

export default orderSlice.reducer
