import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetSalesReportData } from "services/SalesService";
import dayjs from "dayjs";
import { apiGetAdvanceData, apiGetDistData } from "services/AdvanceService";

export const getSalesReportData = createAsyncThunk(
  "salesReport/data/getSalesReportData",
  async (data) => {
    const response = await apiGetSalesReportData(data);
    return response.data;
  }
);
export const getAdvanceReportData = createAsyncThunk(
  "advanceReport/data/getAdvanceReportData",
  async (data) => {
    const response = await apiGetAdvanceData(data);
    return response.data;
  }
);
export const getDistData = createAsyncThunk(
  "Dist/data/getDistData",
  async (data) => {
    const response = await apiGetDistData(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};
export const advanceInitialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  orderPageIndex: 1,
  orderPagezise: 10,
  orderTotal: 0,
  query: "",
  sort: {
    order: "asc",
    key: "",
  },
};

export const initialState = {
  loading: false,
  salesReportData: {},
  advanceReportData: {},
  distData: [],
  tableData: initialTableData,
  advanceTableData: advanceInitialTableData,
  employee: null,
  area: null,
  product: null,
  distributor: null,
  date: dayjs().get("date"),
  dateRange: "",
  month: dayjs().get("month") + 1,
  year: dayjs().get("year"),
  reportType: ["month"],
  reportCategory: "employee",
  sortedColumn: () => {},
};

const dataSlice = createSlice({
  name: "salesReport/data",
  initialState,
  reducers: {
    reset: () => initialState,
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setAdvanceTableData: (state, action) => {
      state.advanceTableData = action.payload;
    },
    setSortedColumn: (state, action) => {
      state.sortedColumn = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setDistributor: (state, action) => {
      state.distributor = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setReportCategory: (state, action) => {
      state.reportCategory = action.payload;
    },
    setAdvanceReportData: (state, action) => {
      state.advanceReportData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesReportData.fulfilled, (state, action) => {
      state.salesReportData = action.payload;
      state.tableData.total = action.payload?.sales?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getSalesReportData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdvanceReportData.fulfilled, (state, action) => {
      state.advanceReportData = action.payload;
      state.advanceTableData.total = action.payload?.meta?.totalItems;
      state.advanceTableData.orderTotal = action.payload?.orders?.meta?.total;
      state.loading = false;
    });
    builder.addCase(getAdvanceReportData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDistData.fulfilled, (state, action) => {
      state.distData = action.payload;
      state.loading = false;
    });
    builder.addCase(getDistData.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  reset,
  setTableData,
  setSortedColumn,
  setEmployee,
  setArea,
  setProduct,
  setDistributor,
  setDate,
  setMonth,
  setYear,
  setReportType,
  setReportCategory,
  setAdvanceTableData,
  setDateRange,
  setAdvanceReportData
} = dataSlice.actions;

export default dataSlice.reducer;
