import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetStateData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}states`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
    // ** for multiple state_id this api has been added
  } else if (data.state_id) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.state_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=created_by_name&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=created_by_name&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
    // ! Below API is only for test purpose as of now.
  } else if (data.state_query) {
    return ApiService.fetchData({
      url: `${BASE_URL}states/searchCriteria[pageSize]=10& 
      searchCriteria[currentPage]=1& 
      searchCriteria[filter_groups][0][filters][0][field]=name& 
      searchCriteria[filter_groups][0][filters][0][value]=${data.state_query}& 
      searchCriteria[filter_groups][0][filters][0][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetStateTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}state/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostState(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}state`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusState(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}state/status`,
    method: "put",
    data,
  });
}

export async function apiPutState(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}state/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverState(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}state/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteState(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}state`,
    method: "delete",
    data,
  });
}
