import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteClient, apiGetClientData, apiPutClient } from 'services/ClientService'

export const getClient = createAsyncThunk('clientEdit/data/getClient', async (data) => {
    const response = await apiGetClientData(data)
    return response.data
})

export const updateClient = async (data, id) => {
    const response = await apiPutClient(data, id)
    return response.data
}

export const deleteClient = async (data) => {
    const response = await apiDeleteClient(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'clientEdit/data',
    initialState: {
        loading: false,
        clientData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getClient.fulfilled, (state, action) => {
            state.clientData = action.payload.client
            state.loading = false
        })
        builder.addCase(getClient.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
