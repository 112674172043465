import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetLocationData } from "services/LocationService";

export const getLocations = createAsyncThunk(
  "locationList/data/getLocations",
  async (data) => {
    const response = await apiGetLocationData(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  sort: {
    order: "",
    key: "",
  },
};

export const initialState = {
  loading: false,
  locationList: [],
  openFeedback: "",
  tableData: initialTableData,
};

const dataSlice = createSlice({
  name: "locationList/data",
  initialState,
  reducers: {
    reset: () => initialState,
    updateLocationList: (state, action) => {
      state.locationList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.locationList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { reset, updateLocationList, setTableData, setSortedColumn } =
  dataSlice.actions;

export default dataSlice.reducer;
