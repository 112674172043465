import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetReminderData, apiPutReminder, apiDeleteReminder, apiChangeStatusReminder } from 'services/ReminderService'

export const getReminders = createAsyncThunk('reminderList/data/getReminders',async (data) => {
    const response = await apiGetReminderData(data)
    return response.data
})

export const changeStatusReminder = async (data) => {
    const response = await apiChangeStatusReminder(data)
    return response.data
}

export const updateReminder = async (data) => {
    const response = await apiPutReminder(data)
    return response.data
}

export const deleteReminder = async (data) => {
    const response = await apiDeleteReminder(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    reminderStatus: 0,
}

export const initialState = {
    loading: false,
    reminderList: [],
    openFeedback: '',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'reminderList/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateReminderList: (state, action) => {
            state.reminderList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getReminders.fulfilled, (state, action) => {
            state.reminderList = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getReminders.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateReminderList, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
