import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetDropdownProdictData(search) {
  let url = `${BASE_URL}product/size/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`
  if (search) {
    url = url + `&searchCriteria[filter_groups][0][filters][0][field]=products.name&searchCriteria[filter_groups][0][filters][0][value]=${search}&searchCriteria[filter_groups][0][filters][0][condition_type]=like`
  }
  return ApiService.fetchData({
    url: url,
    method: "get",
  });
}

export async function apiGetProductData(data) {
  console.log(data, "productData");
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}products`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=type&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=price&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=configurable&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=scheme&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=description&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][0][filters][7][field]=created_by_name&searchCriteria[filter_groups][0][filters][7][value]=${data.query}&searchCriteria[filter_groups][0][filters][7][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=type&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=price&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=configurable&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=scheme&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=description&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][0][filters][7][field]=created_by_name&searchCriteria[filter_groups][0][filters][7][value]=${data.query}&searchCriteria[filter_groups][0][filters][7][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetProductSearchData(data) {
  if (data.search) {
    return ApiService.fetchData({
      url: `${BASE_URL}products/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.search}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.search}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=type&searchCriteria[filter_groups][0][filters][2][value]=${data.search}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=price&searchCriteria[filter_groups][0][filters][3][value]=${data.search}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=configurable&searchCriteria[filter_groups][0][filters][4][value]=${data.search}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=scheme&searchCriteria[filter_groups][0][filters][5][value]=${data.search}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=description&searchCriteria[filter_groups][0][filters][6][value]=${data.search}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][0][filters][7][field]=created_by_name&searchCriteria[filter_groups][0][filters][7][value]=${data.search}&searchCriteria[filter_groups][0][filters][7][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else {
    console.log(data.search, "else");
    return ApiService.fetchData({
      url: `${BASE_URL}products`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetProductTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=type&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=price&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=configurable&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=scheme&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=description&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}product/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=type&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=price&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=configurable&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=scheme&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=description&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostProduct(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}product`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data,
  });
}

export async function apiChangeStatusProduct(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}product/status`,
    method: "put",
    data,
  });
}

export async function apiPutProduct(data, id) {
  return ApiService.fetchData({
    url: `${BASE_URL}product/${id}`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data,
  });
}

export async function apiRecoverProduct(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}product/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteProduct(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}product`,
    method: "delete",
    data,
  });
}
