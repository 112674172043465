import { createSlice } from '@reduxjs/toolkit'

const expenseSlice = createSlice({
    name: 'expense/state',
    initialState: {
        deleteConfirmation: false,
        selectedExpense: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedExpense: (state, action) => {
            state.selectedExpense = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedExpense
} = expenseSlice.actions

export default expenseSlice.reducer
