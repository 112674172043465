import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetSalesReportData(data) {
  if (data.reportCategory === "employee") {
    if (data.reportType[0] === "month") {
      return ApiService.fetchData({
        url: `${BASE_URL}sales/user/month/${data.employee}/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
        method: "get",
        data,
      }).catch((e) => {
        return e.response;
      });
    } else if (data.reportType[0] === "day") {
      return ApiService.fetchData({
        url: `${BASE_URL}sales/user/day/${data.employee}/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
        method: "get",
        data,
      }).catch((e) => {
        return e.response;
      });
    }
  } else if (data.reportCategory === "area") {
    if (data.reportType[0] === "month") {
      return ApiService.fetchData({
        url: `${BASE_URL}sales/area/month/${data.area}/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
        method: "get",
        data,
      }).catch((e) => {
        return e.response;
      });
    } else if (data.reportType[0] === "day") {
      return ApiService.fetchData({
        url: `${BASE_URL}sales/area/day/${data.area}/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
        method: "get",
        data,
      }).catch((e) => {
        return e.response;
      });
    }
  }
}

export async function apiGetSalesProducts(data) {
  return ApiService.fetchData({
    url: "/sales/products",
    method: "post",
    data,
  });
}

export async function apiDeleteSalesProducts(data) {
  return ApiService.fetchData({
    url: "/sales/products/delete",
    method: "delete",
    data,
  });
}

export async function apiGetSalesProduct(params) {
  return ApiService.fetchData({
    url: "/sales/product",
    method: "get",
    params,
  });
}

export async function apiPutSalesProduct(data) {
  return ApiService.fetchData({
    url: "/sales/products/update",
    method: "put",
    data,
  });
}

export async function apiCreateSalesProduct(data) {
  return ApiService.fetchData({
    url: "/sales/products/create",
    method: "post",
    data,
  });
}

export async function apiGetSalesOrders(params) {
  return ApiService.fetchData({
    url: "/sales/orders",
    method: "get",
    params,
  });
}

export async function apiDeleteSalesOrders(data) {
  return ApiService.fetchData({
    url: "/sales/orders/delete",
    method: "delete",
    data,
  });
}

export async function apiGetSalesOrderDetails(params) {
  return ApiService.fetchData({
    url: "/sales/orders-details",
    method: "get",
    params,
  });
}
