import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetLocationData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}locations`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}location/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}locations/searchCriteria[filter_groups][0][filters][0][field]=user_id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}locations/searchCriteria[filter_groups][0][filters][0][field]=user_id&searchCriteria[filter_groups][0][filters][0][value]=${data.MR}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}
