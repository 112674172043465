import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user/state',
    initialState: {
        deleteConfirmation: false,
        selectedUser: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedUser
} = userSlice.actions

export default userSlice.reducer
