import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetMRProductList(data) {
  console.log('called data -->', data)
  if (data.pageSize && !data.sort.order && data.reportType[0] === "month") {
    console.log('called --> if')
    return ApiService.fetchData({
      url: `${BASE_URL}report/product/${data.MR}/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && data.reportType[0] === "month") {
    console.log('called --> else if')
    return ApiService.fetchData({
      url: `${BASE_URL}report/product/${data.MR}/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (
    data.reportType[0] === "all" &&
    data.pageSize &&
    !data.sort.order
  ) {
    // console.log(data.pageSize);
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/product/all/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`
          : `${BASE_URL}report/product/all/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "all" && data.sort.order) {
    return ApiService.fetchData({
      url:
        data.trash !== true
          ? `${BASE_URL}report/product/all/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`
          : `${BASE_URL}report/product/all/${data.selectedDate.start_date}/${data.selectedDate.end_date}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[onlyTrashed]=1`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetMRProductDownloadList(data) {
  if (data.reportType[0] === "month") {
    return ApiService.fetchData({
      url: `${BASE_URL}report/product/${data.MR}/download/${data.selectedDate.start_date}/${data.selectedDate.end_date}`,
      method: "get",
      responseType: "blob",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.reportType[0] === "all") {
    return ApiService.fetchData({
      url: `${BASE_URL}report/product/all/download/${data.selectedDate.start_date}/${data.selectedDate.end_date}`,
      method: "get",
      responseType: "blob",
    }).catch((e) => {
      return e.response;
    });
  }
}
