import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteExpense, apiGetExpenseData, apiPutExpense } from 'services/ExpenseService'

export const getExpense = createAsyncThunk('expenseEdit/data/getExpense', async (data) => {
    const response = await apiGetExpenseData(data)
    return response.data
})

export const updateExpense = async (data, id) => {
    const response = await apiPutExpense(data, id)
    return response.data
}

export const deleteExpense = async (data) => {
    const response = await apiDeleteExpense(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'expenseEdit/data',
    initialState: {
        loading: false,
        expenseData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getExpense.fulfilled, (state, action) => {
            state.expenseData = action.payload.expense
            state.loading = false
        })
        builder.addCase(getExpense.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
