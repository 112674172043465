import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteProduct, apiGetProductData, apiPutProduct } from 'services/ProductService'

export const getProduct = createAsyncThunk('productEdit/data/getProduct', async (data) => {
    const response = await apiGetProductData(data)
    return response.data
})

export const updateProduct = async (data, id) => {
    const response = await apiPutProduct(data, id)
    return response.data
}

export const deleteProduct = async (data) => {
    const response = await apiDeleteProduct(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'productEdit/data',
    initialState: {
        loading: false,
        productData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getProduct.fulfilled, (state, action) => {
            state.productData = action.payload.product
            state.loading = false
        })
        builder.addCase(getProduct.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
