import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetSizeData, apiPutSize, apiDeleteSize, apiChangeStatusSize } from 'services/SizeService'

export const getSizes = createAsyncThunk('sizeList/data/getSizes',async (data) => {
    const response = await apiGetSizeData(data)
    return response.data
})

export const changeStatusSize = async (data) => {
    const response = await apiChangeStatusSize(data)
    return response.data
}

export const updateSize = async (data) => {
    const response = await apiPutSize(data)
    return response.data
}

export const deleteSize = async (data) => {
    const response = await apiDeleteSize(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    sizeStatus: 0,
}

export const initialState = {
    loading: false,
    sizeList: [],
    openFeedback: '',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'sizeList/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateSizeList: (state, action) => {
            state.sizeList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getSizes.fulfilled, (state, action) => {
            state.sizeList = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getSizes.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateSizeList, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
