import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteClient, apiGetClientTrashData, apiRecoverClient } from 'services/ClientService'

export const getTrashClients = createAsyncThunk('clientTrash/data/getTrashClients',async (data) => {
    const response = await apiGetClientTrashData(data)
    return response.data
})

export const recoverClient = async (data) => {
    const response = await apiRecoverClient(data)
    return response.data
}

export const deleteClient = async (data) => {
    const response = await apiDeleteClient(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    clientTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'clientTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashClients.fulfilled, (state, action) => {
            state.clientTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashClients.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
