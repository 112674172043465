import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetUserFeedbacks(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}feedbacks`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}city/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}feedbacks/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=status&searchCriteria[filter_groups][0][filters][0][value]=${data.currentTab}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}feedbacks/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=status&searchCriteria[filter_groups][0][filters][0][value]=${data.currentTab}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}feedbacks/searchCriteria[pageSize]=${data.pageSize}&
searchCriteria[currentPage]=${data.pageIndex}&
searchCriteria[filter_groups][0][filters][0][field]=id&
searchCriteria[filter_groups][0][filters][0][value]=${data.query}&
searchCriteria[filter_groups][0][filters][0][condition_type]=like&
searchCriteria[filter_groups][0][filters][1][field]=description&
searchCriteria[filter_groups][0][filters][1][value]=${data.query}&
searchCriteria[filter_groups][0][filters][1][condition_type]=like&
searchCriteria[filter_groups][0][filters][2][field]=user&
searchCriteria[filter_groups][0][filters][2][value]=${data.query}&
searchCriteria[filter_groups][0][filters][2][condition_type]=like&
searchCriteria[filter_groups][0][filters][3][field]=type&
searchCriteria[filter_groups][0][filters][3][value]=${data.query}&
searchCriteria[filter_groups][0][filters][3][condition_type]=like&
searchCriteria[filter_groups][0][filters][4][field]=status&
searchCriteria[filter_groups][0][filters][4][value]=${data.query}&
searchCriteria[filter_groups][0][filters][4][condition_type]=like&
searchCriteria[filter_groups][0][filters][5][field]=created_at&
searchCriteria[filter_groups][0][filters][5][value]=${data.query}&
searchCriteria[filter_groups][0][filters][5][condition_type]=like&
searchCriteria[filter_groups][0][filters][6][field]=created_by_name&
searchCriteria[filter_groups][0][filters][6][value]=${data.query}&
searchCriteria[filter_groups][0][filters][6][condition_type]=like&
searchCriteria[filter_groups][0][filters][6][field]=updated_by_name&
searchCriteria[filter_groups][0][filters][6][value]=${data.query}&
searchCriteria[filter_groups][0][filters][6][condition_type]=like`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}feedbacks/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&
searchCriteria[filter_groups][0][filters][0][value]=${data.query}&
searchCriteria[filter_groups][0][filters][0][condition_type]=like&
searchCriteria[filter_groups][0][filters][1][field]=description&
searchCriteria[filter_groups][0][filters][1][value]=${data.query}&
searchCriteria[filter_groups][0][filters][1][condition_type]=like&
searchCriteria[filter_groups][0][filters][2][field]=user&
searchCriteria[filter_groups][0][filters][2][value]=${data.query}&
searchCriteria[filter_groups][0][filters][2][condition_type]=like&
searchCriteria[filter_groups][0][filters][3][field]=type&
searchCriteria[filter_groups][0][filters][3][value]=${data.query}&
searchCriteria[filter_groups][0][filters][3][condition_type]=like&
searchCriteria[filter_groups][0][filters][4][field]=status&
searchCriteria[filter_groups][0][filters][4][value]=${data.query}&
searchCriteria[filter_groups][0][filters][4][condition_type]=like&
searchCriteria[filter_groups][0][filters][5][field]=created_at&
searchCriteria[filter_groups][0][filters][5][value]=${data.query}&
searchCriteria[filter_groups][0][filters][5][condition_type]=like&
searchCriteria[filter_groups][0][filters][6][field]=created_by_name&
searchCriteria[filter_groups][0][filters][6][value]=${data.query}&
searchCriteria[filter_groups][0][filters][6][condition_type]=like&
searchCriteria[filter_groups][0][filters][6][field]=updated_by_name&
searchCriteria[filter_groups][0][filters][6][value]=${data.query}&
searchCriteria[filter_groups][0][filters][6][condition_type]=like`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetUserSingleFeedback(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}feedback/${data.id}`,
    method: "get",
  }).catch((e) => {
    return e.response;
  });
}

export async function apiPostFeedback(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}feedback`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data,
  }).catch((e) => {
    return e.response;
  });
}

export async function apiPostFeedbackComment(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}comment`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data,
  }).catch((e) => {
    return e.response;
  });
}

export async function apiUpdateFeedbackComment(data, id) {
  return ApiService.fetchData({
    url: `${BASE_URL}comment/${id}`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    data,
  }).catch((e) => {
    return e.response;
  });
}

export async function apiChangeStatusFeedback(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}feedback/status`,
    method: "put",
    data,
  }).catch((e) => {
    return e.response;
  });
}
