import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAttendanceData,
  apiGetUserAttendanceData,
} from "services/AttendanceService";
import {
  apiGetDayMonthOrderData,
  apiGetMRMonthOrderData,
  apiGetMROrderData,
} from "services/MasterService";

// ! This is ACTIVITY API
export const getAttendance = createAsyncThunk(
  "attendance/data/getAttendance",
  async (data) => {
    const response = await apiGetUserAttendanceData(data);

    return response.data;
  }
);

// ! THIS is ATTENDANCE
export const getNewAttendance = createAsyncThunk(
  "attendance/data/getNewAttendance",
  async (data) => {
    const response = await apiGetAttendanceData(data);
    return response.data;
  }
);

export const getMaster = createAsyncThunk(
  "attendance/data/getMaster",
  async (data) => {
    const response = await apiGetMROrderData(data);
    return response;
  }
);
export const getMasterMonth = createAsyncThunk(
  "attendance/data/getMasterMonth",
  async (data) => {
    const response = await apiGetMRMonthOrderData(data);
    return response;
  }
);
export const getDayMonth = createAsyncThunk(
  "attendance/data/getMasterDayMonth",
  async (data) => {
    const response = await apiGetDayMonthOrderData(data);
    return response;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialState = {
  loading: false,
  attendance: [],
  master: [],
  masterMonth: [],
  newAttendance: [],
  masterDayMonth: [],
  tableData: initialTableData,
  masterTableData: initialTableData,
  masterMonthTableData: initialTableData,
  attendanceTableData: initialTableData,
  masterDayMonthTableData: initialTableData,
};

const dataSlice = createSlice({
  name: "attendance/data",
  initialState: initialState,
  reducers: {
    reset: () => initialState,

    updateAttendanceList: (state, action) => {
      state.attendance = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setMasterTableData: (state, action) => {
      state.masterTableData = action.payload;
    },
    setMasterMonthTableData: (state, action) => {
      state.masterMonthTableData = action.payload;
    },
    setMasterDayMonthTableData: (state, action) => {
      state.masterDayMonthTableData = action.payload;
    },
    setAttendanceTableData: (state, action) => {
      state.attendanceTableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    resetMonthHistoryTableData: (state, action) => {
      state.masterMonthTableData = initialTableData;
    },
    resetDayMonthTableData: (state, action) => {
      state.masterDayMonthTableData = initialTableData;
    },
    resetMasterData: (state, action) => {
      state.masterTableData = initialTableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAttendance.fulfilled, (state, action) => {
      state.attendance = action.payload;
      state.tableData.total =
        action.payload?.attendance?.locations?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getAttendance.pending, (state) => {
      state.loading = true;
    });
    // ! Master Daily
    builder.addCase(getMaster.fulfilled, (state, action) => {
      state.master = action.payload;
      state.masterTableData.total =
        action?.payload?.data?.meta?.totalItems ||
        action?.payload?.data?.invalidLocation?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getMaster.pending, (state) => {
      state.loading = true;
    });
    // ! Master Month
    builder.addCase(getMasterMonth.fulfilled, (state, action) => {
      // console.log("Action", action?.payload);
      state.masterMonth = action?.payload;
      state.masterMonthTableData.total =
        action?.payload?.data?.meta?.totalItems ||
        action?.payload?.data?.invalidLocation?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getMasterMonth.pending, (state) => {
      state.loading = true;
    });

    // ! Day and Month Order and Expense
    builder.addCase(getDayMonth.fulfilled, (state, action) => {
      // console.log("Action", action);
      state.masterDayMonth = action?.payload;
      state.masterDayMonthTableData.total =
        action?.payload?.data?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getDayMonth.pending, (state) => {
      state.loading = true;
    });

    // ! Attendance
    builder.addCase(getNewAttendance.fulfilled, (state, action) => {
      state.newAttendance = action.payload;

      state.attendanceTableData.total =
        action?.payload?.attendance?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getNewAttendance.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  reset,
  updateAttendanceList,
  setTableData,
  setAttendanceTableData,
  setFilterData,
  setSortedColumn,
  setMasterTableData,
  setMasterMonthTableData,
  resetMonthHistoryTableData,
  setMasterDayMonthTableData,
  resetDayMonthTableData,
  resetMasterData,
} = dataSlice.actions;

export default dataSlice.reducer;
