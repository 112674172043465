import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    localStorageOpenFeedback: '',
    email: '',
    userName: '',
    role: '',
    authority: [],
    id: ''
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        setUserName: (state, action) =>  {
            state.userName = action.payload
        },
        setLocalStorageOpenFeedback: (state, action) =>  {
            state.localStorageOpenFeedback = action.payload
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, setUserName, setLocalStorageOpenFeedback } = userSlice.actions

export default userSlice.reducer