import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetReminderData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminders`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=message&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=date_time&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=user_id&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=client&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=client_id&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=user&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][0][filters][7][field]=reminder_type&searchCriteria[filter_groups][0][filters][7][value]=${data.query}&searchCriteria[filter_groups][0][filters][7][condition_type]=like&searchCriteria[filter_groups][0][filters][8][field]=created_by_name&searchCriteria[filter_groups][0][filters][8][value]=${data.query}&searchCriteria[filter_groups][0][filters][8][condition_type]=like&
            `,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminders/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=message&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=date_time&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=user_id&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=client&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=client_id&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&searchCriteria[filter_groups][0][filters][6][field]=user&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&searchCriteria[filter_groups][0][filters][7][field]=reminder_type&searchCriteria[filter_groups][0][filters][7][value]=${data.query}&searchCriteria[filter_groups][0][filters][7][condition_type]=like&searchCriteria[filter_groups][0][filters][8][field]=created_by_name&searchCriteria[filter_groups][0][filters][8][value]=${data.query}&searchCriteria[filter_groups][0][filters][8][condition_type]=like&
            `,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetReminderTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=message&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=date_time&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=user_id&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=client&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=client_id&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}reminder/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=message&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=date_time&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=user_id&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=client&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=client_id&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostReminder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}reminder`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusReminder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}reminder/complete`,
    method: "put",
    data,
  });
}

export async function apiPutReminder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}reminder/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverReminder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}reminder/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteReminder(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}reminder`,
    method: "delete",
    data,
  });
}
