import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetDashboardReportData(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}dashboard/${data.year}/${data.month}/${data.date}`,
    method: "get",
    data,
  }).catch((e) => {
    return e.response;
  });
}

// * Login Data API
export async function apiGetDashboardLoginReportData(data) {
  if (data.sort && data.sort.order) {
    // Code for when data.sort.order is present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.page && data.pagesize) {
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.month && data.year && data.date) {
    // Code for when data.sort.order is not present but month, year, and date are present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  }
}
export async function apiGetDashboardLoginReportMonthData(data) {
  if (data.sort && data.sort.order) {
    // Code for when data.sort.order is present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/month/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.page && data.pagesize) {
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/month/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.month && data.year && data.date) {
    // Code for when data.sort.order is not present but month, year, and date are present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/month/${data.year}/${data.month}/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetDashboardErrorReportData(data) {
  if (data.sort && data.sort.order) {
    // Code for when data.sort.order is present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.page && data.pagesize) {
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=${data.pagesize}&searchCriteria[currentPage]=${data.page}`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  } else if (data.month && data.year && data.date) {
    // Code for when data.sort.order is not present but month, year, and date are present
    return ApiService.fetchData({
      url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`,
      method: "get",
      data,
    }).catch((e) => {
      return e.response;
    });
  }
}

// ! Download Login Activity Report
export async function apiGetLoginActivity(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}dashboard/login/day/${data.year}/${data.month}/${data.date}/download`,
    method: "get",
    responseType: "blob", // Add responseType to get the response as a blob
  }).catch((e) => {
    return e.response;
  });
}
