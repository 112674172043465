import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetProductData,
  apiPutProduct,
  apiDeleteProduct,
  apiChangeStatusProduct,
  apiGetProductSearchData,
  apiGetDropdownProdictData
} from "services/ProductService";

export const getProducts = createAsyncThunk(
  "productList/data/getProducts",
  async (data) => {
    const response = await apiGetProductData(data);
    return response.data;
  }
);

export const getDropdownProduct = createAsyncThunk(
  "productList/data/getDropdownProduct",
  async (data) => {
    const response = await apiGetDropdownProdictData(data);
    return response.data;
  }
);

export const getProductsSearch = createAsyncThunk(
  "productList/data/getProducts",
  async (data) => {
    const response = await apiGetProductSearchData(data);
    return response.data;
  }
);

export const changeStatusProduct = async (data) => {
  const response = await apiChangeStatusProduct(data);
  return response.data;
};

export const updateProduct = async (data) => {
  const response = await apiPutProduct(data);
  return response.data;
};

export const deleteProduct = async (data) => {
  const response = await apiDeleteProduct(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

export const initialState = {
  loading: false,
  productList: [],
  openFeedback: "",
  tableData: initialTableData,
  filterData: initialFilterData,
  dropdownData: [],
};

const dataSlice = createSlice({
  name: "productList/data",
  initialState,
  reducers: {
    reset: () => initialState,
    updateProductList: (state, action) => {
      state.productList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.productList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDropdownProduct.fulfilled, (state, action) => {
      state.dropdownData = action.payload.items;
    });
  },
});

export const {
  reset,
  updateProductList,
  setTableData,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
