import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteSize, apiGetSizeTrashData, apiRecoverSize } from 'services/SizeService'

export const getTrashSizes = createAsyncThunk('sizeTrash/data/getTrashSizes',async (data) => {
    const response = await apiGetSizeTrashData(data)
    return response.data
})

export const recoverSize = async (data) => {
    const response = await apiRecoverSize(data)
    return response.data
}

export const deleteSize = async (data) => {
    const response = await apiDeleteSize(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    sizeTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'sizeTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashSizes.fulfilled, (state, action) => {
            state.sizeTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashSizes.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
