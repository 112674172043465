import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetUserSingleFeedback, apiPostFeedbackComment, apiUpdateFeedbackComment } from 'services/FeedbackService'

export const getFeedback = createAsyncThunk('feedbackSingle/data/getFeedback', async (data) => {
    const response = await apiGetUserSingleFeedback(data)
    return response.data
})

export const updateFeedbackComment = async (data, id) => {
    const response = await apiUpdateFeedbackComment(data, id)
    return response.data
}

const dataSlice = createSlice({
    name: 'feedbackSingle/data',
    initialState: {
        loading: false,
        feedbackSingle: {},
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getFeedback.fulfilled, (state, action) => {
            state.feedbackSingle = action.payload.feedback
            state.loading = false
        })
        builder.addCase(getFeedback.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
