import { BASE_URL } from 'constants/api.constant'
import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}login`,
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}register`,
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}logout`,
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}user/password/forgot`,
        method: 'post',
        data
    })
}

export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}user/password/change`,
        method: 'put',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: `${BASE_URL}user/password/reset${data.query}`,
        method: 'post',
        data: {
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation
        }
    })
}
