import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAreaData,
  apiPutArea,
  apiDeleteArea,
  apiChangeStatusArea,
  apiGetAreaSearch,
} from "services/AreaService";

export const getAreas = createAsyncThunk(
  "areaList/data/getAreas",
  async (data) => {
    const response = await apiGetAreaData(data);
    return response.data;
  }
);

export const getFormattedAreas = createAsyncThunk(
  "areaList/data/getFormattedAreas",
  async (data) => {
    const response = await apiGetAreaSearch(data);

    return response.data;
  }
);

export const changeStatusArea = async (data) => {
  const response = await apiChangeStatusArea(data);
  return response.data;
};

export const updateArea = async (data) => {
  const response = await apiPutArea(data);
  return response.data;
};

export const deleteArea = async (data) => {
  const response = await apiDeleteArea(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  areaStatus: 0,
};

export const initialState = {
  loading: false,
  areaList: [],
  openFeedback: "",
  formattedAreaList: [],
  tableData: initialTableData,
  filterData: initialFilterData,
};

const dataSlice = createSlice({
  name: "areaList/data",
  initialState,
  reducers: {
    reset: () => initialState,
    updateAreaList: (state, action) => {
      state.areaList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.areaList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getAreas.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFormattedAreas.fulfilled, (state, action) => {
      state.formattedAreaList = action.payload.items;
      state.loading = false;
    });
    builder.addCase(getFormattedAreas.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  reset,
  updateAreaList,
  setTableData,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
