import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetUserData, apiPutUser, apiDeleteUser, apiChangeStatusUser } from 'services/UserService'

export const getUsers = createAsyncThunk('userList/data/getUsers',async (data) => {
    const response = await apiGetUserData(data)
    return response.data
})

export const changeStatusUser = async (data) => {
    const response = await apiChangeStatusUser(data)
    return response.data
}

export const updateUser = async (data) => {
    const response = await apiPutUser(data)
    return response.data
}

export const deleteUser = async (data) => {
    const response = await apiDeleteUser(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    userStatus: 0,
}

export const initialState = {
    loading: false,
    userList: [],
    openFeedback: '',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'userList/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateUserList: (state, action) => {
            state.userList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getUsers.fulfilled, (state, action) => {
            state.userList = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateUserList, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
