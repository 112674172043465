import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiChangeStatusFeedback, apiGetUserFeedbacks, apiGetUserSingleFeedback } from 'services/FeedbackService'

export const getFeedbacks = createAsyncThunk('feedback/data/getFeedbacks',async (data) => {
    const response = await apiGetUserFeedbacks(data)
    return response.data
})

export const changeStatusFeedback = async (data) => {
    const response = await apiChangeStatusFeedback(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    feedbackStatus: 0,
}

export const initialState = {
    loading: false,
    feedbackList: [],
    openFeedback: '',
    currentTab: 'all',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'feedback/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateFeedbackList: (state, action) => {
            state.feedbackList = action.payload
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getFeedbacks.fulfilled, (state, action) => {
            state.feedbackList = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getFeedbacks.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateFeedbackList, setCurrentTab, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
