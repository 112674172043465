import { createSlice } from '@reduxjs/toolkit'

const locationSlice = createSlice({
    name: 'location/state',
    initialState: {
        sortedColumn: () => {},
    },
    reducers: {
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
    },
})

export const {
    setSortedColumn,
} = locationSlice.actions

export default locationSlice.reducer
