import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetDashboardErrorReportData,
  apiGetDashboardLoginReportData,
  apiGetDashboardLoginReportMonthData,
  apiGetDashboardReportData,
} from "services/Dashboard";

export const getDashboardReportData = createAsyncThunk(
  "dashboardReport/data/getDashboardReportData",
  async (data) => {
    const response = await apiGetDashboardReportData(data);
    return response;
  }
);

// * Added new API call function to get Login data
export const getDashboardLoginReportData = createAsyncThunk(
  "dashboardReport/data/getDashboardLoginReportData",
  async (data) => {
    const response = await apiGetDashboardLoginReportData(data);
    return response;
  }
);
export const getDashboardLoginReportMonthData = createAsyncThunk(
  "dashboardReport/data/getDashboardLoginReportMonthData",
  async (data) => {
    const response = await apiGetDashboardLoginReportMonthData(data);
    return response;
  }
);
export const getDashboardErrorReportData = createAsyncThunk(
  "dashboardReport/data/getDashboardErrorReportData",
  async (data) => {
    const response = await apiGetDashboardErrorReportData(data);
    return response;
  }
);

export const initialState = {
  loading: false,
  dashboardReportData: {},
  dashboardLoginData: {},
  dashboardLoginMonthData: {},
  dashboardErrorData: {},
  tableData: {
    pageIndex: 1,
    pageSize: 10,
    sort: {
      order: "",
      key: "",
    },
  },
  logintableData: {
    pageIndex: 1,
    pageSize: 10,
    sort: {
      order: "",
      key: "",
    },
  },
  logintableMonthData: {
    pageIndex: 1,
    pageSize: 10,
    sort: {
      order: "",
      key: "",
    },
  },
  error_tableData: {
    pageIndex: 1,
    pageSize: 10,
    sort: {
      order: "",
      key: "",
    },
  },
};

const dataSlice = createSlice({
  name: "dashboardReport/data",
  initialState: initialState,
  // * Adding this for sorting and pagnation for login report data
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setLoginTableData: (state, action) => {
      state.logintableData = action.payload;
    },
    setLoginTableMonthData: (state, action) => {
      state.logintableMonthData = action.payload;
    },
    setErrorTableData: (state, action) => {
      state.error_tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(setTableData, (state, action) => {
    //   state.tableData = action.payload; // Update the tableData in the Redux state
    // });

    builder.addCase(getDashboardReportData.fulfilled, (state, action) => {
      state.dashboardReportData = action.payload;
      state.loading = false;
    });
    builder.addCase(getDashboardReportData.pending, (state) => {
      state.loading = true;
    });

    // ! Added reducer for Login data action
    builder.addCase(getDashboardLoginReportData.fulfilled, (state, action) => {
      state.dashboardLoginData = action.payload;
      state.logintableData.total = action.payload?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getDashboardLoginReportData.pending, (state) => {
      state.loading = true;
    });

    // ! dashboard error report data
    builder.addCase(getDashboardErrorReportData.fulfilled, (state, action) => {
      state.dashboardErrorData = action.payload;
      state.error_tableData.total = action.payload?.meta?.totalItems;
      state.loading = false;
    });
    builder.addCase(getDashboardErrorReportData.pending, (state) => {
      state.loading = true;
    });

    // ! dashboard Login monthly data
    builder.addCase(
      getDashboardLoginReportMonthData.fulfilled,
      (state, action) => {
        state.dashboardLoginMonthData = action.payload;
        state.logintableMonthData.total = action.payload?.meta?.totalItems;
        state.loading = false;
      }
    );
    builder.addCase(getDashboardLoginReportMonthData.pending, (state) => {
      state.loading = true;
    });
  },
});
export const {
  setTableData,
  setLoginTableData,
  setErrorTableData,
  setLoginTableMonthData,
} = dataSlice.actions;
export default dataSlice.reducer;
