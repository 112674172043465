import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteProduct, apiGetProductTrashData, apiRecoverProduct } from 'services/ProductService'

export const getTrashProducts = createAsyncThunk('productTrash/data/getTrashProducts',async (data) => {
    const response = await apiGetProductTrashData(data)
    return response.data
})

export const recoverProduct = async (data) => {
    const response = await apiRecoverProduct(data)
    return response.data
}

export const deleteProduct = async (data) => {
    const response = await apiDeleteProduct(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    productTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'productTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashProducts.fulfilled, (state, action) => {
            state.productTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashProducts.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
