import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetClientData, apiPutClient, apiDeleteClient, apiChangeStatusClient } from 'services/ClientService'

export const getClients = createAsyncThunk('clientList/data/getClients',async (data) => {
    const response = await apiGetClientData(data)
    return response.data
})

export const changeStatusClient = async (data) => {
    const response = await apiChangeStatusClient(data)
    return response.data
}

export const updateClient = async (data) => {
    const response = await apiPutClient(data)
    return response.data
}

export const deleteClient = async (data) => {
    const response = await apiDeleteClient(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    clientStatus: 0,
}

export const initialState = {
    loading: false,
    clientList: [],
    openFeedback: '',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'clientList/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateClientList: (state, action) => {
            state.clientList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getClients.fulfilled, (state, action) => {
            state.clientList = action.payload?.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getClients.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateClientList, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
