import { createSlice } from '@reduxjs/toolkit'

const expenseReportSlice = createSlice({
    name: 'expenseReport/state',
    initialState: {
        deleteConfirmation: false,
        selectedLocation: '',
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedLocation
} = expenseReportSlice.actions

export default expenseReportSlice.reducer
