import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'productTrash/state',
    initialState: {
        deleteConfirmation: false,
        recoverConfirmation: false,
        selectedProduct: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleRecoverConfirmation: (state, action) => {
            state.recoverConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation,
    toggleRecoverConfirmation,
    setSortedColumn,
    setSelectedProduct
} = stateSlice.actions

export default stateSlice.reducer
