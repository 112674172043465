import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetOrderData,
  apiPutOrder,
  apiDeleteOrder,
  apiChangeStatusOrder,
  apiGetHistoryOrderData,
} from "services/OrderService";

export const getOrders = createAsyncThunk(
  "orderList/data/getOrders",
  async (data) => {
    const response = await apiGetOrderData(data);
    return response.data;
  }
);
export const getHistoryOrders = createAsyncThunk(
  "orderList/data/getHistoryOrders",
  async (data) => {
    const response = await apiGetHistoryOrderData(data);
    return response.data;
  }
);

export const updateOrder = async (data) => {
  const response = await apiPutOrder(data);
  return response.data;
};

export const changeStatusOrder = async (data) => {
  const response = await apiChangeStatusOrder(data);
  return response.data;
};

export const deleteOrder = async (data) => {
  const response = await apiDeleteOrder(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  orderStatus: 0,
};

export const initialState = {
  loading: false,
  orderList: [],
  orderHistoryList: [],
  openFeedback: "",
  tableData: initialTableData,
  orderHistoryTableData: initialTableData,
  filterData: initialFilterData,
};

const dataSlice = createSlice({
  name: "orderList/data",
  initialState,
  reducers: {
    reset: () => initialState,

    updateOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setHistoryTableData: (state, action) => {
      state.orderHistoryTableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    resetOrderHistoryTableData: (state) => {
      state.orderHistoryTableData = initialTableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.orderList = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHistoryOrders.fulfilled, (state, action) => {
      state.orderHistoryList = action.payload.items;
      state.orderHistoryTableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getHistoryOrders.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  reset,
  updateOrderList,
  setTableData,
  setHistoryTableData,
  setFilterData,
  setSortedColumn,
  resetOrderHistoryTableData,
} = dataSlice.actions;

export default dataSlice.reducer;
