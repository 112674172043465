import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiDeleteOrder,
  apiGetOrderTrashData,
  apiRecoverOrder,
} from "services/OrderService";

export const getTrashOrders = createAsyncThunk(
  "orderTrash/data/getTrashOrders",
  async (data) => {
    const response = await apiGetOrderTrashData(data);
    return response.data;
  }
);

export const recoverOrder = async (data) => {
  const response = await apiRecoverOrder(data);
  return response.data;
};

export const deleteOrder = async (data) => {
  const response = await apiDeleteOrder(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialState = {
  loading: false,
  orderTrash: [],
  openFeedback: "",
  tableData: initialTableData,
};

const dataSlice = createSlice({
  name: "orderTrash/data",
  initialState,
  reducers: {
    reset: () => initialState,
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrashOrders.fulfilled, (state, action) => {
      state.orderTrash = action.payload.items;
      state.tableData.total = action.payload?.meta?.totalItems;
      state.openFeedback = action.payload?.open_feedback;
      state.loading = false;
    });
    builder.addCase(getTrashOrders.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { reset, setTableData, setSortedColumns } = dataSlice.actions;

export default dataSlice.reducer;
