import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetSizeData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}sizes`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}sizes/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}sizes/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}sizes/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=size&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=created_by_name&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}sizes/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=size&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=created_by_name&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetSizeTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=size&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}size/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=size&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostSize(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}size`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusSize(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}size/status`,
    method: "put",
    data,
  });
}

export async function apiPutSize(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}size/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverSize(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}size/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteSize(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}size`,
    method: "delete",
    data,
  });
}
