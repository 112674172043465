import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteState, apiGetStateData, apiPutState } from 'services/StateService'

export const getState = createAsyncThunk('stateEdit/data/getState', async (data) => {
    const response = await apiGetStateData(data)
    return response.data
})

export const updateState = async (data) => {
    const response = await apiPutState(data)
    return response.data
}

export const deleteState = async (data) => {
    const response = await apiDeleteState(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'stateEdit/data',
    initialState: {
        loading: false,
        stateData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getState.fulfilled, (state, action) => {
            state.stateData = action.payload.state
            state.loading = false
        })
        builder.addCase(getState.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
