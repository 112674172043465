import { createSlice } from '@reduxjs/toolkit'

const feedbackSlice = createSlice({
    name: 'feedback/state',
    initialState: {
        deleteConfirmation: false,
        selectedFeedback: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedFeedback: (state, action) => {
            state.selectedFeedback = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedFeedback
} = feedbackSlice.actions

export default feedbackSlice.reducer
