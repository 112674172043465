import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetCityData, apiPutCity, apiDeleteCity, apiChangeStatusCity } from 'services/CityService'

export const getCities = createAsyncThunk('cityList/data/getCities',async (data) => {
    const response = await apiGetCityData(data)
    return response.data
})

export const changeStatusCity = async (data) => {
    const response = await apiChangeStatusCity(data)
    return response.data
}

export const updateCity = async (data) => {
    const response = await apiPutCity(data)
    return response.data
}

export const deleteCity = async (data) => {
    const response = await apiDeleteCity(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    cityStatus: 0,
}

export const initialState = {
    loading: false,
    cityList: [],
    openFeedback: '',
    tableData: initialTableData,
    filterData: initialFilterData,
}

const dataSlice = createSlice({
    name: 'cityList/data',
    initialState,
    reducers: {
        reset: () => initialState,
        updateCityList: (state, action) => {
            state.cityList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getCities.fulfilled, (state, action) => {
            state.cityList = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getCities.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, updateCityList, setTableData, setFilterData, setSortedColumn } = dataSlice.actions

export default dataSlice.reducer
