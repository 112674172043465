import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetClientRequiredFields, apiGetGeneralSettings, apiPutGeneralSettings, apiPutSettingClientRequiredFields } from 'services/SettingService'

export const getGeneralSetting = createAsyncThunk('setting/data/getGeneralSettings', async (data) => {
    const response = await apiGetGeneralSettings(data)
    return response.data
})

export const updateGeneralSetting = async (data) => {
    const response = await apiPutGeneralSettings(data)
    return response.data
}

export const getClientRequiredField = createAsyncThunk('setting/data/getClientRequiredFields', async (data) => {
    const response = await apiGetClientRequiredFields(data)
    return response.data
})

export const updateClientRequiredField = async (data) => {
    const response = await apiPutSettingClientRequiredFields(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'setting/data',
    initialState: {
        generalSettingloading: false,
        generalSettingData: {},
        clientRequiredFieldData: {},
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getGeneralSetting.fulfilled, (state, action) => {
            state.generalSettingData = action.payload.settings
            state.generalSettingloading = false
        })
        builder.addCase(getGeneralSetting.pending, (state) => {
            state.generalSettingloading = true
        })
		builder.addCase(getClientRequiredField.fulfilled, (state, action) => {
            state.clientRequiredFieldData = action.payload.settings
            state.loading = false
        })
        builder.addCase(getClientRequiredField.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
