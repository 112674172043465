import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'reminderTrash/state',
    initialState: {
        deleteConfirmation: false,
        recoverConfirmation: false,
        selectedReminder: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleRecoverConfirmation: (state, action) => {
            state.recoverConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedReminder: (state, action) => {
            state.selectedReminder = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation,
    toggleRecoverConfirmation,
    setSortedColumn,
    setSelectedReminder
} = stateSlice.actions

export default stateSlice.reducer
