import { BASE_URL } from "constants/api.constant"
import ApiService from "./ApiService"

export async function apiGetExpenseReportData(data) {
    if (data.reportType[0] === 'year' && !data.sort.order) {
        return ApiService.fetchData({
            url: `${BASE_URL}ledger/${data.reportType[0]}/${data.MR}/${data.year}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
            method: 'get'
        }).catch(e => {return e.response})
    }
    else if (data.reportType[0] === 'year' && data.sort.order) {
        return ApiService.fetchData({
            url: `${BASE_URL}ledger/${data.reportType[0]}/${data.MR}/${data.year}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
            method: 'get'
        }).catch(e => {return e.response})
    }
    else if (data.reportType[0] === 'month' && !data.sort.order) {
        return ApiService.fetchData({
            url: `${BASE_URL}ledger/${data.reportType[0]}/${data.MR}/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
            method: 'get'
        }).catch(e => {return e.response})
    }
    else if (data.reportType[0] === 'month' && data.sort.order) {
        return ApiService.fetchData({
            url: `${BASE_URL}ledger/${data.reportType[0]}/${data.MR}/${data.year}/${data.month}/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
            method: 'get'
        }).catch(e => {return e.response})
    }
}