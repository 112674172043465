import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'cityList/state',
    initialState: {
        deleteConfirmation: false,
        selectedCity: [],
        sortedColumn: () => {},
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload
        },
    },
})

export const { 
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedCity
} = stateSlice.actions

export default stateSlice.reducer
