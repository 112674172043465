import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import dashboardReport from "../views/dashboard/DashboardReport/store";
import setting from "../views/account/Settings/store";
import order from "../views/order/OrderForm/store";
import orderTrash from "../views/order/OrderTrash/store";
import orderList from "../views/order/OrderList/store";
import orderUpdate from "../views/order/OrderUpdate/store";
import reminder from "../views/reminder/ReminderList/store";
import reminderTrash from "../views/reminder/ReminderTrash/store";
import reminderUpdate from "../views/reminder/ReminderUpdate/store";
import client from "../views/client/ClientList/store";
import clientTrash from "../views/client/ClientTrash/store";
import clientUpdate from "../views/client/ClientUpdate/store";
import salesReport from "../views/sales/SalesReport/store";
import product from "../views/product/ProductList/store";
import productTrash from "../views/product/ProductTrash/store";
import productUpdate from "../views/product/ProductUpdate/store";
import user from "../views/user/UserList/store";
import userTrash from "../views/user/UserTrash/store";
import userUpdate from "../views/user/UserUpdate/store";
import attendance from "../views/user/UserAttendance/store";
import location from "../views/user/LocationTracking/store";
import feedback from "../views/feedback/FeedbackList/store";
import feedbackSingle from "../views/feedback/FeedbackSingle/store";
import size from "../views/size/SizeList/store";
import sizeTrash from "../views/size/SizeTrash/store";
import sizeUpdate from "../views/size/SizeUpdate/store";
import expense from "../views/expense/ExpenseList/store";
import expenseTrash from "../views/expense/ExpenseTrash/store";
import expenseReport from "../views/expense/ExpenseReport/store";
import expenseUpdate from "../views/expense/ExpenseUpdate/store";
import area from "../views/area/AreaList/store";
import areaTrash from "../views/area/AreaTrash/store";
import areaUpdate from "../views/area/AreaUpdate/store";
import city from "../views/city/CityList/store";
import cityTrash from "../views/city/CityTrash/store";
import cityUpdate from "../views/city/CityUpdate/store";
import states from "../views/state/StateList/store";
import stateTrash from "../views/state/StateTrash/store";
import stateUpdate from "../views/state/StateUpdate/store";
import mrProduct from "../views/order/ProductWise/store";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    dashboardReport,
    mrProduct,
    setting,
    order,
    orderTrash,
    orderList,
    orderUpdate,
    reminder,
    reminderTrash,
    reminderUpdate,
    client,
    clientTrash,
    clientUpdate,
    salesReport,
    product,
    productTrash,
    productUpdate,
    user,
    userTrash,
    userUpdate,
    attendance,
    location,
    feedback,
    feedbackSingle,
    size,
    sizeTrash,
    sizeUpdate,
    expense,
    expenseTrash,
    expenseReport,
    expenseUpdate,
    area,
    areaTrash,
    areaUpdate,
    city,
    cityTrash,
    cityUpdate,
    states,
    stateTrash,
    stateUpdate,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
