import { BASE_URL } from "constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetAreaData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (typeof data === "string") {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=10&searchCriteria[currentPage]=1&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=district&searchCriteria[filter_groups][0][filters][1][value]=${data}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=region&searchCriteria[filter_groups][0][filters][2][value]=${data}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=state&searchCriteria[filter_groups][0][filters][3][value]=${data}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.city && !data.area) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[filter_groups][0][filters][0][field]=city_id&searchCriteria[filter_groups][0][filters][0][value]=${data.city}&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.city && data.area && !data.area_id) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.area}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=city_id&searchCriteria[filter_groups][1][filters][0][value]=${data.city}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.city && data.area && data.area_id) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.area_id}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=name&searchCriteria[filter_groups][1][filters][0][value]=${data.area}&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[filter_groups][2][filters][0][field]=city_id&searchCriteria[filter_groups][2][filters][0][value]=${data.city}&searchCriteria[filter_groups][2][filters][0][condition_type]=eq&searchCriteria[filter_groups][3][filters][0][field]=status&searchCriteria[filter_groups][3][filters][0][value]=1&searchCriteria[filter_groups][3][filters][0][condition_type]=eq&searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/${data.id}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=city&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&&searchCriteria[filter_groups][0][filters][6][field]=created_by_name&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=district&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=region&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&searchCriteria[filter_groups][0][filters][4][field]=state&searchCriteria[filter_groups][0][filters][4][value]=${data.query}&searchCriteria[filter_groups][0][filters][4][condition_type]=like&searchCriteria[filter_groups][0][filters][5][field]=city&searchCriteria[filter_groups][0][filters][5][value]=${data.query}&searchCriteria[filter_groups][0][filters][5][condition_type]=like&&searchCriteria[filter_groups][0][filters][6][field]=created_by_name&searchCriteria[filter_groups][0][filters][6][value]=${data.query}&searchCriteria[filter_groups][0][filters][6][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetAreaTrashData(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/trashed`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.pageSize && !data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.sort.order && !data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query && !data.sort.order) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=city&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=state&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.query) {
    return ApiService.fetchData({
      url: `${BASE_URL}area/trashed/searchCriteria[pageSize]=${data.pageSize}&searchCriteria[currentPage]=${data.pageIndex}&searchCriteria[sortOrders][0][fields]=${data.sort.key}&searchCriteria[sortOrders][0][direction]=${data.sort.order}&searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=${data.query}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][0][filters][1][field]=name&searchCriteria[filter_groups][0][filters][1][value]=${data.query}&searchCriteria[filter_groups][0][filters][1][condition_type]=like&searchCriteria[filter_groups][0][filters][2][field]=city&searchCriteria[filter_groups][0][filters][2][value]=${data.query}&searchCriteria[filter_groups][0][filters][2][condition_type]=like&searchCriteria[filter_groups][0][filters][3][field]=state&searchCriteria[filter_groups][0][filters][3][value]=${data.query}&searchCriteria[filter_groups][0][filters][3][condition_type]=like&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiGetAreaSearch(data) {
  if (data === undefined) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&searchCriteria[filter_groups][0][filters][0][field]=status&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
  } else if (data.search) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&searchCriteria[sortOrders][0][fields]=name&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=${data.search}&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&`,
      method: "get",
    }).catch((e) => {
      return e.response;
    });
    // ** Api is changed as we are sending multiple city's ID
  } else if (data.id) {
    return ApiService.fetchData({
      url: `${BASE_URL}areas/searchCriteria[pageSize]=50&searchCriteria[currentPage]=1&searchCriteria[filter_groups][0][filters][0][field]=city_id&searchCriteria[filter_groups][0][filters][0][value]=${data.id}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&`,

      method: "get",
    }).catch((e) => {
      return e.response;
    });
  }
}

export async function apiPostArea(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}area`,
    method: "post",
    data,
  });
}

export async function apiChangeStatusArea(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}area/status`,
    method: "put",
    data,
  });
}

export async function apiPutArea(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}area/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiRecoverArea(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}area/recover`,
    method: "post",
    data,
  });
}

export async function apiDeleteArea(data) {
  return ApiService.fetchData({
    url: `${BASE_URL}area`,
    method: "delete",
    data,
  });
}
