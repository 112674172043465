import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteExpense, apiGetExpenseTrashData, apiRecoverExpense } from 'services/ExpenseService'

export const getTrashExpenses = createAsyncThunk('expenseTrash/data/getTrashExpenses',async (data) => {
    const response = await apiGetExpenseTrashData(data)
    return response.data
})

export const recoverExpense = async (data) => {
    const response = await apiRecoverExpense(data)
    return response.data
}

export const deleteExpense = async (data) => {
    const response = await apiDeleteExpense(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    expenseTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'expenseTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashExpenses.fulfilled, (state, action) => {
            state.expenseTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashExpenses.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
