import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { apiGetExpenseReportData } from "services/ExpenseReportService";

export const getExpenseReport = createAsyncThunk(
  "expenseReport/data/getExpenseReport",
  async (data) => {
    const response = await apiGetExpenseReportData(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  locationStatus: 0,
};

export const initialState = {
  loading: false,
  expenseReport: [],
  reportType: ["month"],
  MR: null,
  month: dayjs().get("month") + 1,
  year: dayjs().get("year"),
  tableData: initialTableData,
};

const dataSlice = createSlice({
  name: "expenseReport/data",
  initialState,
  reducers: {
    ledgerReset: () => initialState,
    updateExpenseReportList: (state, action) => {
      state.expenseReport = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setLedgerMR: (state, action) => {
      state.MR = action.payload;
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExpenseReport.fulfilled, (state, action) => {
      state.expenseReport = action.payload;
      state.tableData.total = action.payload?.items?.meta?.total;
      state.loading = false;
    });
    builder.addCase(getExpenseReport.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  ledgerReset,
  updateExpenseReportList,
  setReportType,
  setLedgerMR,
  setMonth,
  setYear,
  setTableData,
  setFilterData,
  setSortedColumn,
} = dataSlice.actions;

export default dataSlice.reducer;
