import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteSize, apiGetSizeData, apiPutSize } from 'services/SizeService'

export const getSize = createAsyncThunk('sizeEdit/data/getSize', async (data) => {
    const response = await apiGetSizeData(data)
    return response.data
})

export const updateSize = async (data) => {
    const response = await apiPutSize(data)
    return response.data
}

export const deleteSize = async (data) => {
    const response = await apiDeleteSize(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'sizeEdit/data',
    initialState: {
        loading: false,
        sizeData: [],
        
    },
    reducers: {
    },
    extraReducers: (builder) => {
		builder.addCase(getSize.fulfilled, (state, action) => {
            state.sizeData = action.payload.size
            state.loading = false
        })
        builder.addCase(getSize.pending, (state) => {
            state.loading = true
        })
    }
})

export default dataSlice.reducer
