import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteUser, apiGetUserTrashData, apiRecoverUser } from 'services/UserService'

export const getTrashUsers = createAsyncThunk('userTrash/data/getTrashUsers',async (data) => {
    const response = await apiGetUserTrashData(data)
    return response.data
})

export const recoverUser = async (data) => {
    const response = await apiRecoverUser(data)
    return response.data
}

export const deleteUser = async (data) => {
    const response = await apiDeleteUser(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

export const initialState = {
    loading: false,
    userTrash: [],
    openFeedback: '',
    tableData: initialTableData,
}

const dataSlice = createSlice({
    name: 'userTrash/data',
    initialState,
    reducers: {
        reset: () => initialState,
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
		builder.addCase(getTrashUsers.fulfilled, (state, action) => {
            state.userTrash = action.payload.items
            state.tableData.total = action.payload?.meta?.totalItems
            state.openFeedback = action.payload?.open_feedback
            state.loading = false
        })
        builder.addCase(getTrashUsers.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset, setTableData, setSortedColumns } = dataSlice.actions

export default dataSlice.reducer
