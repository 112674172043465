import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const stateSlice = createSlice({
    name: 'dashboardReport/state',
    initialState: {
        month: dayjs().get('month') + 1,
        year: dayjs().get('year'),
    },
    reducers: {
        setMonth: (state, action) => {
            state.month = action.payload
        },
        setYear: (state, action) => {
            state.year = action.payload
        },
    },
})

export const { setMonth, setYear } = stateSlice.actions

export default stateSlice.reducer
